import {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {Image} from "react-bootstrap";

const ProductModal = ({productData, setProducts, showModal, setshowModal}) => {

    const [show, setShow] = useState(false);
    const [miqdor, setMiqdor] = useState(0)
    const [metr, setMetr] = useState(0)

    useEffect(() => {
        setShow(showModal)

    }, [showModal])

    const handleClose = () => setshowModal(false);

    const onChangeMiqdor = (value) => {
        setMiqdor(value.target.value)
    }

    const onChangeMetr = (value) => {
        setMetr(value.target.value)
    }


    const setStorage = () => {
        // Retrieve products from localStorage
        let products = localStorage.getItem('products');

        // Parse products or initialize as an empty array if products is null
        products = products ? JSON.parse(products) : [];

        // Check if the product already exists in the products array
        const existingProductIndex = products.findIndex(product => product.id === productData.id);


        if (Object.keys(productData).length > 0) {

            if (existingProductIndex > -1) {
                // Product exists, update the "miqdor" field
                products[existingProductIndex].miqdor = miqdor ?? 0; // Adjust increment logic as needed
                products[existingProductIndex].metr = metr ?? 0; // Adjust increment logic as needed
            } else {
                // Product does not exist, add it to the array
                productData.miqdor = miqdor ?? 0; // Initialize "miqdor" if it's a new product
                productData.metr = metr ?? 0; // Initialize "miqdor" if it's a new product
                products.push(productData);
            }
        }


        // Store the updated array back in localStorage
        localStorage.setItem('products', JSON.stringify(products));

        setProducts(products);

        // Log the new product data and the updated array
        console.log(productData);
        console.log(products);

        handleClose()
    }


    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{productData.firma} {productData.category} {productData.nomi}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {productData.img !== 'noimage' && productData.img !== '' ?
                        <div className={'text-center mb-3'}>
                            <Image src={'/public/storage/temporary/' + productData.img}
                                   alt={productData.name} height="300px"/>
                        </div>

                        : ''
                    }

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Miqdor kiriting</Form.Label>
                        <Form.Control type="number" placeholder="0" onChange={onChangeMiqdor}/>
                        <Form.Label>Metr kiriting</Form.Label>
                        <Form.Control type="number" placeholder="0" onChange={onChangeMetr}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Yopish
                    </Button>
                    <Button variant="primary"
                            onClick={setStorage}>
                        Saqlash
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ProductModal;