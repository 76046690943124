import axios from "axios";
import {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {Image} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import SuccessToast from "./SuccessToast";


const MijozModal = ({mijozModalShow, modalHide, setProducts}) => {

    const [showMijoz, setShowMijoz] = useState(false);

    const [mijoz, setMijoz] = useState({});

    const [fish, setFish] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState("");

    const [showCreate, setShowCreate] = useState(false);

    const [products, setProductsZakaz] = useState([]);

    const [toast, setToast] = useState(false);

    useEffect(() => {
        setShowMijoz(mijozModalShow)
    }, [mijozModalShow]);

    useEffect(() => {
        const localstorageProducts = JSON.parse(localStorage.getItem("products"));
        setShowMijoz(mijozModalShow)

        if (phone.length === 12) {
            getMijoz().then(mijoz => {

                if (Object.keys(mijoz).length > 0) {
                    setMijoz(mijoz)

                    localStorage.setItem("mijoz", JSON.stringify(mijoz));

                    setFish(mijoz.fish);
                    setAddress(mijoz.adress);

                    console.log(mijoz)


                } else {
                    localStorage.setItem("mijoz", "{}");
                    console.log('Mijoz topilmadi')

                    setFish('')
                    setAddress('')
                    setMijoz({})
                }
                setShowCreate(true)
            })
        } else {
            localStorage.setItem("mijoz", "{}");

            setFish('')
            setAddress('')
            setMijoz({})
        }

        setProductsZakaz(localstorageProducts ?? []);

    }, [phone]);


    const getMijoz = async () => {
        try {
            const res = await axios.get('/public/api/v1/mijoz_by_tel', {
                params: {
                    tel: phone
                }
            });
            return res.data.data;
        } catch (e) {
            console.error('Error fetching data:', e);
        }
        return {};
    }


    const createZakaz = async () => {
        try {

            const tovarList = products.map(product => ({
                tovar_id: product.id,
                dona: product.miqdor,
                metr: product.metr,
                narx: product.sot_narx,
                valyuta: product.sot_val
            }));

            const data = {
                sender_id: mijoz.id ?? 0,
                sana: getFormattedDate(),
                dona: 0,
                izoh: 'Saytdan',
                metr: 0,
                client_id: mijoz.id ?? 0,
                sklad_id: 1,
                tovarList: tovarList,
                kirimList: [],
                mijoz: {
                    tel: phone,
                    fish: fish,
                    adress: address
                },
            }

            if (tovarList.length > 0) {
                const res = await axios.post('/public/api/v1/create_mijoz_zakaz', data);

                return res.data.status;
            }

            return false
        } catch (e) {
            console.error('Error fetching data:', e);
        }
        return false;
    }

    const getFormattedDate = () => {
        const now = new Date();

        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        const day = String(now.getDate()).padStart(2, '0');

        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };


    const handleClose = () => {
        setShowMijoz(false)
        modalHide()
        setFish('')
        setAddress('')
        setMijoz({})
        localStorage.setItem("mijoz", "{}");

    }

    const onChangePhone = (e) => setPhone(e.target.value)
    const onChangeFish = (e) => setFish(e.target.value)
    const onChangeAddress = (e) => setAddress(e.target.value)

    const zakazBerish = (e) => {

        e.preventDefault()

        console.log('Zakaz berish')

        createZakaz().then(res => {
            console.log(res)

            setToast(true)

            modalHide(false)
            setProducts([])
            localStorage.setItem("products", "[]");

            localStorage.setItem("mijoz", "{}");
        }).catch(err => console.log(err))


    }

    return (
        <div>

            <SuccessToast status={toast}/>


            <Modal show={showMijoz} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Mijoz</Modal.Title>
                </Modal.Header>
                <Form onSubmit={zakazBerish}>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Telelefon raqam</Form.Label>
                            <Form.Control type="number" placeholder="998" defaultValue={'998'}
                                          onChange={onChangePhone}/>

                            {
                                showCreate ?
                                    <>
                                        <Form.Label className={'my-2'}>F.I.SH</Form.Label>
                                        <Form.Control type="text" placeholder="F.I.SH" value={fish}
                                                      onChange={onChangeFish}/>
                                        <Form.Label className={'my-2'}>Manzil</Form.Label>
                                        <Form.Control type="text" placeholder="Manzil" value={address}
                                                      onChange={onChangeAddress}/>
                                    </>
                                    : ''

                            }

                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="w-100"
                            variant="warning"
                            type="submit"
                            disabled={!fish || !address || !phone}
                        >
                            <i className="fas fa-shopping-basket me-2"></i>

                            Zakaz Berish
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>
        </div>
    );
}

export default MijozModal;
