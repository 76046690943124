import React, {useRef, useEffect, useState} from 'react';
import Webcam from 'react-webcam';
import Quagga from 'quagga';
import axios from "axios";
import Basket from "./Basket";
import ProductModal from "./ProductModal";
import CreateZakaz from "./CreateZakaz";

const BarcodeScanner = () => {
    const [barcode, setBarcode] = useState('');
    const [scanned, setScanned] = useState(false);
    const [scanning, setScanning] = useState(false); // State to control scanning
    const webcamRef = useRef(null);
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState([]);
    const [showModal, setshowModal] = useState(false);


    useEffect(() => {

        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            alert('This browser does not support getUserMedia API. Please try using a modern browser.');
            return;
        } else {
            console.log('working 1')
        }

        const localstorageProducts = JSON.parse(localStorage.getItem("products"));

        if (scanned) {
            getProduct().then(productData => {
                setProduct(productData)

                if (Object.keys(productData).length > 0) {

                    setshowModal(true)
                    console.log('showModal :' + showModal)
                }
            });
        }

        setProducts(localstorageProducts ?? []);

        if (!scanning) return; // If not scanning, do not initialize Quagga

        // Initialize QuaggaJS
        Quagga.init({
            inputStream: {
                name: 'Live',
                type: 'LiveStream',
                target: webcamRef.current.video,
                constraints: {
                    facingMode: 'environment',
                },
            },
            decoder: {
                readers: ['code_128_reader', 'ean_reader', 'ean_8_reader', 'upc_reader'],
            },
        }, (err) => {
            if (err) {
                console.error(err);
                return;
            }
            Quagga.start();
        });

        // Handle the result of barcode scanning
        Quagga.onDetected((data) => {
            if (data.codeResult && data.codeResult.code) {
                setBarcode(data.codeResult.code);
                setScanned(true);
                Quagga.stop();
                setScanning(false);

            }
        });


        return () => {
            Quagga.stop();
        };
    }, [scanning]);


    const startScanning = () => {
        setBarcode(''); // Clear previous barcode
        setScanned(false); // Reset scanned state
        setScanning(true); // Start scanning
    };

    const stopScanning = () => {
        setBarcode(''); // Clear previous barcode
        setScanned(false); // Reset scanned state
        setScanning(false); // Start scanning
    };

    const getProduct = async () => {
        try {
            const res = await axios.get('/public/api/v1/tovar_barcode', {
                params: {
                    sklad_id: 1,
                    barcode: barcode
                }
            });
            return res.data.data;
        } catch (e) {
            console.error('Error fetching data:', e);
        }
        return {};
    }


    return (
        <div className={'container'}>
            {
                scanning ?
                    <div style={{width: '100%', height: 'auto'}}>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={{
                                facingMode: 'environment',
                                width: 1280, // Higher resolution for clearer zoom
                                height: 720,
                                advanced: [{
                                    zoom: 1,
                                    focusMode: 'auto'
                                }] // Applying 2x zoom
                            }}
                            style={{
                                width: '100%',  // Cropping the camera view for zoom effect
                                height: '260px',
                                objectFit: 'cover', // Ensure the video fills the container
                            }}
                        />

                    </div>
                    : ''
            }


            <p>Barcode: {barcode}</p>
            <div className={'btn-group'}>
                {
                    scanning ?
                        <button onClick={stopScanning} className={'btn btn-danger text-bold'}>
                            Stop
                        </button>
                        :
                        <button onClick={startScanning} disabled={scanning} className={'btn btn-primary text-bold'}>
                            Start
                        </button>
                }
            </div>

            <Basket
                products={products}
                setProducts={setProducts}
            />

            <ProductModal
                productData={product}
                setProducts={setProducts}
                showModal={showModal}
                setshowModal={setshowModal}
            />


            <CreateZakaz
                products={products}
                setProducts={setProducts}
            />

        </div>
    );
};

export default BarcodeScanner;
